import React, { useState } from 'react';
import Select from 'react-select';
import es from '../../../content/images/es.png';
import fr from '../../../content/images/french.png';
import imageRight from '../../../content/images/gallery/1.jpg';
import imageLeft from '../../../content/images/gallery/3.jpg';
import shopImage from '../../../content/images/gallery/kasse.jpg';
import gb from '../../../content/images/gb.png';
import it from '../../../content/images/it.png';
import jp from '../../../content/images/jp.png';
import ru from '../../../content/images/ru.png';
import {
  BottomImage, CenterImage, Container,


  DropDown
} from './aboutlogo.css.js';

const AboutLogo = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: (
      <div>
        <img src={gb} alt="english" height="25px" width="25px" />
      </div>
    ),
  });

  const options = [
    {
      value: 1,
      label: (
        <div>
          <img src={gb} alt="english" height="25px" width="25px" />
        </div>
      ),
    },
    {
      value: 2,
      label: (
        <div>
          <img src={fr} alt="french" height="25px" width="25px" />
        </div>
      ),
    },
    {
      value: 4,
      label: (
        <div>
          <img src={it} alt="italian" height="25px" width="25px" />
        </div>
      ),
    },
    {
      value: 7,
      label: (
        <div>
          <img src={es} alt="spanish" height="25px" width="25px" />
        </div>
      ),
    },
    {
      value: 5,
      label: (
        <div>
          <img src={jp} alt="japanese" height="25px" width="25px" />
        </div>
      ),
    },
    {
      value: 6,
      label: (
        <div>
          <img src={ru} alt="russian" height="25px" width="25px" />
        </div>
      ),
    },
  ];

  const english = (
    <span>
      Founded by Wolfgang Lindenhofer and Michael Pascher in 2019, Wolfmich is a
      hybrid between an archival retail space and a vintage store. <br /> <br />
      Stocking a curated selection of designer pieces, independent labels as
      well as unsigned vintage and accessories, we look for the value outside of
      conventional fashion channels. Our attraction is both in the garments
      quality and story alike, with a drive to create an eclectic variation not
      bound by season, but with a focus placed on the fabulous.
      <br /> <br /> The store houses a collection equal parts mens and
      womenswear, come by if you feel curious.
    </span>
  );

  const french = (
    <span>
      Créé en 2017 par Wolfgang Lindenhofer et Michael Pascher, Wolfmich est un
      concept hybride entre espace de vente d’archives et boutique vintage.
      <br />
      <br />
      Regroupant un choix très sélectif de pièces de créateurs, de labels
      indépendants ainsi que du vintage et des accessoires, nous recherchons des
      pièces de valeur en dehors des sentiers battus de la mode. Nos choix se
      portent sur des vêtements autant pour leur qualité que pour leur histoire
      avec la volonté de créer un ensemble éclectique qui ne soit pas lié à la
      saison mais à l’extraordinaire.
      <br />
      <br /> La boutique abrite une collection qui s’adresse autant aux femmes
      qu’aux hommes, alors si vous êtes curieux, venez-y dénicher la perle rare!
    </span>
  );

  const italian = (
    <span>
      Fondato nel 2019 da Wolfgang Lindenhofer e Michael Pascher, Wolfmich è uno
      spazio ibrido dove si uniscono un retail ed un vintage store.
      <br />
      <br /> Offrendo una selezione accurata di diversi item di design, di label
      indipedenti così come gli accessori e l&apos;unsigned vintage, cerchiamo
      sempre di trovare l&apos;essenza fuori dai soliti schemi e canali
      convenzionali del Fashion.
      <br />
      <br /> Siamo attratti sia dalla qualità degli indumenti che dalla loro
      storia, facendoci guidare dalla diversità eclettica ma senza vincolarci
      alla stagionalità del capo, con un forte focus sul concept di unico.
      <br />
      <br /> Lo store ospita collezioni sia donna che uomo, vieni a trovarci se
      sei curioso di scoprire Wolfmich.
    </span>
  );

  const spanish = (
    <span>
      Fundada por Wolfgang Lindenhofer y Michael Pascher en 2019, Wolfmich es un híbrido entre espacio de venta de archivo y tienda vintage.
      <br />
      <br /> A partir de una selección de piezas de diseñador y de firmas independientes así como de ropa y accesorios vintage, encontramos valor fuera de los canales convencionales de la moda. Nuestra atracción a éstas piezas aparece en base a la calidad e historia de cada una, y por la intención de crear un conjunto ecléctico que no esté definido por la temporada si no por un carácter extraordinario.
      <br />
      <br /> La tienda alberga colecciones masculina y femenina a partes iguales. No dudes hacer una visita si sientes curiosidad por descubrir Wolfmich.
    </span>
  );

  const japanese = (
    <span lang="ja-jp">
      2019年に Wolfgang Lindenhofer と Michael Pascher によって創設された
      Wolfmich は服から小物まであつかうビンテージのハイブリットなストアです。
      <br />
      <br />
      私達が選ぶビンテージアイテムは有名無名を問いません。従来のファッションの世界の外側にある価値を大切に、デザイン性の高いアクセサリーや服などを取り扱かっています。
      <br />
      <br />
      私達の商品の魅力は品質の高さと、そのものが持つストーリーの両方です。季節に関係なく素晴らしいと感じたものを大切に取り扱い、さまざまな価値観を生み出していきます。
      <br />
      <br />
      お店にはメンズとウィメンズのコレクションがあります。きっとあなたのお気に入りが見つかるでしょう。
    </span>
  );

  const russian = (
    <span>
      Основанный Вольфгангом Линденхофером и Микаелем Пашером в 2019, Wolfmich
      (Вольфмих) стал пространством, включающим в себя архивные и винтажные
      коллекции.
      <br />
      <br /> Курируя тщательную подборку дизайнерских вещей, независимых лейблов
      на ряду с винтажом и аксессуарами, мы в первую очередь смотрим на ценность
      вне традиционных источников моды. Наша привлекательность заключается как в
      качестве одежды, так и в её сюжете, со стремлением создать эклектическую
      вариацию, опирающуюся не на сезон, а акцентируя себя на великолепие
      изделий.
      <br />
      <br /> Мы предоставляем равный выбор как мужских, так и женских вещей –
      заходите, если Вам интересно.
    </span>
  );

  const text = {
    1: english,
    2: french,
    4: italian,
    5: japanese,
    6: russian,
    7: spanish
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      textAlign: 'right',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      textAlign: 'right',
    }),
    indicatorSeperator: () => ({
      display: 'none',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      color: 'black',
      cursor: 'pointer',
      fontSize: '150%',
      width: 50,
      textAlign: 'center',
    }),
    singleValue: (provided, state) => ({
      textDecoration: 'underline',
      textAlign: 'right',
    }),
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Container>
        <CenterImage>
          <img
            src={shopImage}
            style={{
              width: '100%',
            }}
            alt="Shop"
          />
        </CenterImage>
        <div
          style={{
            borderWidth: '1px',
            borderColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'space-between',
            maxWidth: '500px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <DropDown>
              <Select
                components={{ DropdownIndicator: () => null }}
                styles={customStyles}
                value={selectedOption}
                onChange={selected => setSelectedOption(selected)}
                options={options}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: 'lightgray',
                    primary: 'gray',
                  },
                })}
              />
            </DropDown>
          </div>
          <p
            style={{
              lineHeight: '1.5',
              textAlign: 'justify',
              fontFamily: 'Muli',
            }}
          >
            {text[selectedOption.value]}
          </p>
        </div>
      </Container>
      <BottomImage>
        <img
          src={imageRight}
          style={{
            width: '100%',
            alignSelf: 'left',
          }}
          alt="Shop_1"
        />
      </BottomImage>
      <BottomImage>
        <img
          src={imageLeft}
          style={{
            width: '100%',
          }}
          alt="Shop_2"
        />
      </BottomImage>
    </div>
  );
};

export default AboutLogo;
