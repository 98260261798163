import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${MEDIA.TABLET`
    display: block;
    margin: 1em;
  `};
`;

export const CenterImage = styled.div`
  padding: 5px;
  max-width: 700px;
  margin: 10px;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  color: #000;
`;

export const BottomImage = styled.div`
  padding: 5px;
  max-width: 1050px;
  margin: 10px;
`;
